import React from 'react'
import styled from '@emotion/styled'
import ThemeConsumer from '@/hoc/ThemeConsumer'
import { mobile } from '@/utils/media'
import { mq } from '@/utils/helpers'
import Typography from '@/components/common/Typography'

const Root = styled.div`
  padding: 100px 0 120px;
  @media ${mq.and(mobile)} {
    padding: 48px 0 64px;
  }
`

const List = styled.dl`
`

const Q = styled(Typography)`
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'Q';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    background-color: ${props => props.theme.primary.base};
    color: ${props => props.theme.primary.contrast.base};
  }
  position: relative;
  padding-top: 16px;
  padding-left: 88px;
  font-weight: bold;
  color: ${props => props.theme.gray.e};
  min-height: 60px;

  &:not(:first-of-type) {
    margin-top: 24px;
  }

  @media ${mq.and(mobile)} {
    min-height: 48px;
    padding-left: 64px;
    padding-top: 10px;
    &::before {
      width: 48px;
      height: 48px;
      font-size: 22px;
    }
  }
`

const A = styled(Typography)`
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    content: 'A';
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 100%;
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    font-size: 28px;
    color: ${props => props.theme.primary.base};
    border: 1px solid ${props => props.theme.primary.base};
  }
  position: relative;
  margin-top: 12px;
  padding-top: 16px;
  padding-left: 88px;
  min-height: 60px;

  @media ${mq.and(mobile)} {
    min-height: 48px;
    padding-top: 10px;
    padding-left: 64px;
    &::before {
      width: 48px;
      height: 48px;
      font-size: 22px;
    }
  }
`

const FAQList = props => {
  return (
    <Root>
      <List>
        <Q variant="body1" component="dt" theme={props.theme}>相談するだけで料金がかかりますか？</Q>
        <A variant="body2" component="dd" theme={props.theme}>ご相談は無料です。お気軽にご相談ください。</A>
        <Q variant="body1" component="dt" theme={props.theme}>デモ環境はありますか？</Q>
        <A variant="body2" component="dd" theme={props.theme}>はい。用意いたしております。環境へのログインURLとアカウント情報をお伝えしますので、まずはお問い合わせください。</A>
        <Q variant="body1" component="dt" theme={props.theme}>個人事業主でも使えますか？</Q>
        <A variant="body2" component="dd" theme={props.theme}>お使いいただけます。個人事業主様や小規模企業様にも最適です。</A>
        <Q variant="body1" component="dt" theme={props.theme}>どのような業種で使えますか？</Q>
        <A variant="body2" component="dd" theme={props.theme}>製造業、小売業、卸売業、サービス業など物販や役務などあらゆる利用シーンを想定しています。</A>
        <Q variant="body1" component="dt" theme={props.theme}>経費精算だけなど一部のモジュールだけで使えますか？</Q>
        <A variant="body2" component="dd" theme={props.theme}>お使いいただけますが、一部モジュールだけのご利用でも基本料金は変わりません。</A>
        <Q variant="body1" component="dt" theme={props.theme}>オーダーメイド要件を入れるといくらになりますか？</Q>
        <A variant="body2" component="dd" theme={props.theme}>オーダーメイド要件には個別のお見積りが必要となります。内容や規模などによって金額は変わります。まずはご相談ください。</A>
      </List>
    </Root>
  )
}
export default ThemeConsumer(FAQList)
